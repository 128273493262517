import { scrollbar } from 'style/global.styles';
import { css } from '@emotion/react';
import { fontSize, lineHeight } from '@prototyp/gatsby-plugin-gumball/mixins';
import { font } from '@prototyp/gatsby-plugin-gumball/utils';
import * as buttonStyles from 'modules/app/styles/Button.styles';

export const root = css`
  position: relative;

  margin-bottom: 32px;
  position: relative;
  label {
    display: block;
    font-size: ${fontSize('small')};
    line-height: ${lineHeight('small')};
    color: hsl(var(--color-grayscale-6));
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker {
    font-family: Aeonik;
    background: hsl(var(--color-white-11));
    border: none;
    min-width: 250px;
    width: 100%;
    border-radius: 6px;
    z-index: 1000;
    padding: calc(var(--unit) * 5);
    margin-top: calc(var(--unit) * 2);
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  }

  .react-datepicker__month-container {
    width: 100%;
    background: hsl(var(--color-white-11));
  }
  .react-datepicker__month {
    margin: 0;
    border-radius: 6px;
  }
  .react-datepicker__month-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .react-datepicker__month-text {
    margin: none;
    height: calc(var(--unit) * 10);
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-xsmall);
    line-height: var(--line-height-xsmall);
    margin: 0;
    border-radius: 3px;
    color: hsl(var(--color-grayscale-7));
    transition: color 0.2s, background-color 0.2s;

    &:hover {
      color: hsl(var(--color-text-1));
      background: hsl(var(--color-grayscale-1));
    }
  }

  .react-datepicker__month--disabled {
    color: hsl(var(--color-grayscale-4));
  }

  .react-datepicker-popper {
    margin: 0;
    width: 100%;
    z-index: 1000;
  }

  .react-datepicker-year-header {
    margin-bottom: calc(var(--unit) * 5);
  }

  .react-datepicker__header {
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);
    border: none;
    background: hsl(var(--color-white-11));
    border-radius: 0;
    font-weight: normal;
  }

  .react-datepicker__navigation--next {
    border-left-color: hsl(var(--color-grayscale-4));
    outline: none;
    top: 30px;
    right: 20px;

    &:hover {
      border-left-color: hsl(var(--color-text-1));
    }
  }
  .react-datepicker__navigation--previous {
    border-right-color: hsl(var(--color-grayscale-4));
    outline: none;
    top: 30px;
    left: 20px;

    &:hover {
      border-right-color: hsl(var(--color-text-1));
    }
  }

  .react-datepicker__month-text--keyboard-selected {
    color: hsl(var(--color-text-1));
    font-weight: var(--font-weight-bold);
    background: transparent;
  }

  .react-datepicker__month--selected {
    background-color: hsl(var(--color-robin-2));
    color: hsl(var(--color-text-1));
    font-weight: normal;

    &:hover {
      background-color: hsl(var(--color-robin-3));
    }
  }

  /* WITH DAYS */

  .react-datepicker__year-dropdown-container {
    padding: var(--unit);
  }
  .react-datepicker__year-read-view--down-arrow {
    border-top-color: hsl(var(--color-text-1));
  }

  .react-datepicker__current-month {
    font-weight: normal;
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);
    margin-bottom: calc(var(--unit) * 2);
  }

  .react-datepicker__week {
    display: flex;
    justify-content: space-evenly;
  }
  .react-datepicker__day {
    outline: none;
    border-radius: 3px;
    margin: 0;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: hsl(var(--color-grayscale-6));
    transition: color 0.2s, background-color 0.2s;

    &:hover {
      color: hsl(var(--color-text-1));
      background: hsl(var(--color-grayscale-1));
    }
  }
  .react-datepicker__day--disabled {
    color: hsl(var(--color-grayscale-4));

    &:hover {
      background: transparent;
      color: hsl(var(--color-grayscale-4));
    }
  }

  .react-datepicker__day--today {
    background-color: transparent;
    color: hsl(var(--color-text-1));
  }

  .react-datepicker__day--keyboard-selected {
    background-color: transparent;
    color: hsl(var(--color-text-1));
  }

  .react-datepicker__day--selected {
    background-color: hsl(var(--color-robin-2));
    color: hsl(var(--color-text-1));

    &:hover {
      background-color: hsl(var(--color-robin-3));
    }
  }

  .react-datepicker__day-name {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    color: hsl(var(--color-grayscale-4));
    font-size: var(--font-size-tiny);
    line-height: var(--line-height-tiny);
  }
  .react-datepicker__day-names {
    display: flex;
    justify-content: space-evenly;
  }
  .react-datepicker__year-dropdown {
    background: hsl(var(--color-white-11));
    border: none;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    padding: calc(var(--unit) * 3);
    top: 38px;

    ${scrollbar};
  }

  .react-datepicker__year-read-view--down-arrow {
    border-top-color: hsl(var(--color-grayscale-4));
    outline: none;
    border-width: 6px;

    &:hover {
      border-top-color: hsl(var(--color-text-1));
    }
  }

  .react-datepicker__year-option {
    color: hsl(var(--color-grayscale-6));
    padding: var(--unit);
    border-radius: 3px;
    transition: color 0.2s, background 0.3s;

    &:hover {
      color: hsl(var(--color-text-1));
      background: hsl(var(--color-robin-2));
    }
  }
  .react-datepicker__year-option--selected_year {
    color: hsl(var(--color-text-1));
    background: hsl(var(--color-robin-2));

    &:hover {
      background: hsl(var(--color-robin-3));
    }
  }

  .react-datepicker__year-option--selected {
    display: none;
  }

  .react-datepicker-wrapper {
    width: 100%;
    margin-top: 3px;

    input[type='text'] {
      width: 100%;
      padding: calc(var(--unit) * 2) calc(var(--unit) + 1px)
        calc(var(--unit) + 1px) 0;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid hsl(var(--color-borderLight-8));
      outline: none;
      font-size: var(--font-size-base);
      line-height: var(--line-height-base);
    }
  }

  .react-datepicker__input-container {
    input[disabled] {
      opacity: 0.6;
    }
  }

  /* WITH TIME */

  .react-datepicker__input-time-container {
    margin: 0;
    margin-top: calc(var(--unit) * 5);
  }

  .react-datepicker-time__input-container {
    width: 100%;
  }

  .react-datepicker__input-time-container
    .react-datepicker-time__input-container
    .react-datepicker-time__input {
    margin: 0;
    width: 100%;

    input {
      outline: none;
      width: 100%;
      border: none;
      border-bottom: 1px solid hsl(var(--color-borderUltraLight-9));
      font-size: var(--font-size-base);
      line-height: var(--line-height-base);
      padding-bottom: calc(var(--unit) * 2);
      transition: border 0.2s;
      margin-bottom: var(--unit);

      &:hover,
      &:active {
        border-bottom: 1px solid hsl(var(--color-borderDark-5));
      }
    }
  }

  .react-datepicker-time__caption {
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);
    color: hsl(var(--color-grayscale-4));
  }

  .datepicker__time__dropdown {
    background: red;
  }
`;

export const currentlyWorking = css`
  color: hsl(var(--color-text-1));
  background: hsl(var(--color-robin-2));
  font-size: var(--font-size-xsmall);
  line-height: var(--line-height-xsmall);
  border-radius: 3px;
  width: 100%;
  padding: calc(var(--unit) * 2 + 2px) 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover,
  &:active {
    cursor: pointer;
    background-color: hsl(var(--color-robin-3));
  }
`;

export const error = css`
  label {
    color: hsl(var(--color-error-7));
  }

  .react-datepicker-wrapper {
    input[type='text'] {
      border-bottom: 1px solid hsl(var(--color-error-7));
    }
  }
`;

export const calendarIcon = css`
  position: absolute;
  bottom: calc(var(--unit) * 3);
  right: 0;
`;

export const iconWithError = css`
  bottom: calc(var(--unit) * 9);
`;

export const companyOpeningsDateTitle = css`
  ${font.size.micro};
  letter-spacing: 1px;
  text-transform: uppercase;
  color: hsl(var(--color-grayscale-4));
  margin: 0;
`;

export const companyOpeningsDateWrapper = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: calc(var(--unit) * 15);
`;

export const button = css`
  ${buttonStyles.root};
  ${buttonStyles.tiny};
  ${buttonStyles.svgButton};
  ${font.weight.regular};
  padding: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;

  > p {
    text-align: left;
  }
`;

export const buttonDark = css`
  ${button};
  ${buttonStyles.svgButtonDark}
`;

export const datePickerButton = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
